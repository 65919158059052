import { FaLinkedin } from "react-icons/fa";
import Sites from "./Sites";

function App() {
  return (
    <div className="App pt-12 pb-9 px-6 md:h-screen flex flex-col items-center justify-center">
      <Header title="Rodney Gracia" subtitle="Front End Architect" />
      <Links />
      <Sites />
    </div>
  );
}

export default App;

function Header(props) {
  return (
    <header className="App-header text-center">
      <img className="w-16 block mx-auto mb-3 rounded-full" src="https://avatars.githubusercontent.com/u/499431?v=4" alt="Rodney Gracia"/>
      <h1 className="font-bold text-center text-orange-300 text-5xl md:text-7xl lg:text-8xl">
        {props.title}
      </h1>
      <h2 className="italic text-center text-orange-300 text-3xl md:text-5xl lg:text-6xl mt-4">
        {props.subtitle}
      </h2>
    </header>
  );
}

function Links() {
  return (
    <section>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/rodney-gracia/"
            rel="noreferrer"
            className="flex items-center text-2xl text-orange-100 underline hover:text-orange-300 mt-4"
          >
            <FaLinkedin className="mr-2" />
            <span className="sr-only">LinekdIn</span>
          </a>
        </li>
      </ul>
    </section>
  );
}
