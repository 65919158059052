import { siteData } from "./data";

function Sites() {
  return (
    <section className="mt-9">
      <h2 className="font-bold text-center text-orange-300 text-3xl md:text-4xl">
        Recent Work
      </h2>
      <ul className="text-center">
        {siteData.map((data, key) => {
          return (
            <li className="my-2" key={key}>
              <a
                className="underline text-orange-100 hover:text-orange-200 text-xl md:text-3xl"
                target="_blank"
                href={data.url}
                rel="noreferrer"
              >
                {data.name}
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default Sites;
