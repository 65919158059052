export const siteData = [
    {
        "id": 1,
        "url": "https://barnard.edu/",
        "name": "Barnard College"
    },
    {
        "id": 2,
        "url": "https://www.miles.edu/",
        "name": "Miles College"
    },
    {
        "id": 3,
        "url": "https://www.pace.edu/",
        "name": "Pace University"
    },
    {
        "id": 4,
        "url": "https://newbrunswick.rutgers.edu/",
        "name": "Rutgers New Brunswick"
    },
    {
        "id": 5,
        "url": "https://research.rutgers.edu/",
        "name": "Rutgers Research"
    },
    {
        "id": 6,
        "url": "https://www.rutgers.edu/",
        "name": "Rutgers University"
    },
    {
        "id": 7,
        "url": "https://biocomplexity.virginia.edu/",
        "name": "UVA Biocomplexity Institute"
    }
]